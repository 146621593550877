import React from "react";

const Projects = () => {
  return (
    <>
      <section id="projects">
        <ul role="list" className="pt-24">
          <li className="group/item relative flex items-center justify-between rounded-xl p-4 hover:bg-slate-100">
            <div>
              <div>
                <img class="h-100 w-100 rounded-xl" src="./Soil.png" alt="" />
              </div>
              <div class="w-full text-sm leading-6">
                <a
                  href="https://github.com/cnkamorn/SOIL-Market-Full-Stack-E-Commerce-website"
                  target="_blank"
                  class="font-semibold text-slate-900 text-center"
                >
                  <h2 className="font-semibold text-lg mt-2">
                  SOIL Market Full Stack E-Commerce Website
                  </h2>
                </a>
                <div class="text-slate-650 mt-2">
                  A full-stack e-commerce web application using JavaScript with
                  React for the front-end, and GraphQL and WebSocket for the
                  admin panel. Created a RESTful API with Node.js and Express.js
                  to handle client-server communication, and implemented
                  Sequelize ORM for efficient MySQL interactions on the backend.
                  Additionally, developed a pub-sub service and utilized
                  Chart.js for real-time data visualization on the admin
                  dashboard. Unit tests with Jest.
                </div>
                <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      React
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      NodeJS
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      ExpressJS
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      SQL
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      GraphQL
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      MySQL
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Sequelize
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Jest
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {" "}
              <a
                href="https://github.com/cnkamorn/SOIL-Market-Full-Stack-E-Commerce-website"
                target="_blank"
                class="group/edit md:invisible relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-slate-500 transition hover:bg-slate-200 group-hover/item:visible"
              >
                <span class="font-semibold transition group-hover/edit:text-gray-700">
                  Github
                </span>
                <svg
                  class="mt-px h-5 w-5 text-slate-400 transition group-hover/edit:translate-x-0.5 group-hover/edit:text-slate-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.loom.com/share/db084ff5ed55456a8acb710b126c6b49?sid=d887107d-d47d-491d-9d23-7ef96dca2b03"
                target="_blank"
                class="mt-5 group/edit md:invisible relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-slate-500 transition hover:bg-slate-200 group-hover/item:visible"
              >
                <span class="font-semibold transition group-hover/edit:text-gray-700">
                  Video
                </span>
                <svg
                  class="mt-px h-5 w-5 text-slate-400 transition group-hover/edit:translate-x-0.5 group-hover/edit:text-slate-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </li>
          <li className="group/item relative flex items-center justify-between rounded-xl p-4 hover:bg-slate-100">
            <div>
              <div>
                <img class="h-100 w-100 rounded-xl" src="./aws.png" alt="" />
              </div>
              <div class="w-full text-sm leading-6">
                <a
                  href="https://github.com/cnkamorn/Music-Subscription-AWS?tab=readme-ov-file"
                  target="_blank"
                  class="font-semibold text-slate-900 text-center"
                >
                  <h2 className="font-semibold text-lg mt-2">
                    Cloud Project - Music Subscription
                  </h2>
                </a>
                <div class="text-slate-650 mt-2">
                  A Cloud AWS web application project using AWS services that
                  allows users to subscribe and query songs. <br /> Used
                  JavaScript to send requests to API Gateway with API Key, which
                  interacted with Lambda functions to create and read data from
                  DynamoDB. <br /> Used S3 to store images and utilized Lambda
                  functions to generate pre-signed URLs for displaying images on
                  the frontend. Hosted the web application on EC2, accessible
                  via the EC2 DNS.
                </div>
                <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Python
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Java
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      HTML
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Bootstrap5
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Javascript
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {" "}
              <a
                href="https://github.com/cnkamorn/Music-Subscription-AWS?tab=readme-ov-file"
                target="_blank"
                class="group/edit md:invisible relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-slate-500 transition hover:bg-slate-200 group-hover/item:visible"
              >
                <span class="font-semibold transition group-hover/edit:text-gray-700">
                  Github
                </span>
                <svg
                  class="mt-px h-5 w-5 text-slate-400 transition group-hover/edit:translate-x-0.5 group-hover/edit:text-slate-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.loom.com/share/ad72a27bd4584fd09b4a1e40c68d7bdf?sid=8adc4038-4e71-4723-959a-652eb799bdce"
                target="_blank"
                class="mt-5 group/edit md:invisible relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-slate-500 transition hover:bg-slate-200 group-hover/item:visible"
              >
                <span class="font-semibold transition group-hover/edit:text-gray-700">
                  Video
                </span>
                <svg
                  class="mt-px h-5 w-5 text-slate-400 transition group-hover/edit:translate-x-0.5 group-hover/edit:text-slate-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </li>
          <li className="group/item relative flex items-center justify-between rounded-xl p-4 hover:bg-slate-100">
            <div>
              <div>
                <img class="h-100 w-100 rounded-xl" src="./javafx.png" alt="" />
              </div>
              <div class="w-full text-sm leading-6">
                <a
                  href="https://github.com/cnkamorn/Music-Subscription-AWS?tab=readme-ov-file"
                  target="_blank"
                  class="font-semibold text-slate-900 text-center"
                >
                  <h2 className="font-semibold text-lg mt-2">
                    Java Project - Social Media Management Desktop Application
                  </h2>
                </a>
                <div class="text-slate-650 mt-2">
                  Social-Media-Management-Application is a JavaFX GUI
                  application built on the Model-View-Controller (MVC)
                  architecture. This application allows multiple users to create
                  profiles, log in, upload csv file and manage social media
                  posts. It provides basic functionalities for all users and
                  advanced functionalities for VIP users.
                </div>
                <ul class="mt-2 flex flex-wrap" aria-label="Technologies used:">
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      Java
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      JavaFX
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      OOP
                    </div>
                  </li>
                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      SQLite
                    </div>
                  </li>

                  <li class="mr-1.5 mt-2">
                    <div class="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-sky-500 ">
                      MVC
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {" "}
              <a
                href="https://github.com/cnkamorn/Social-Media-Management-Application"
                target="_blank"
                class="group/edit md:invisible relative flex items-center whitespace-nowrap rounded-full py-1 pl-4 pr-3 text-sm text-slate-500 transition hover:bg-slate-200 group-hover/item:visible"
              >
                <span class="font-semibold transition group-hover/edit:text-gray-700">
                  Github
                </span>
                <svg
                  class="mt-px h-5 w-5 text-slate-400 transition group-hover/edit:translate-x-0.5 group-hover/edit:text-slate-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Projects;

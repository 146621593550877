import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="text-xl animate-fade-right py-5
    "
    >
      Chanakan worked as a Senior Technical Support Consultant at{" "}
      <a href="https://useinsider.com/" className="underline">
        <b>Insider</b>
      </a>
      , which he finds incredibly fulfilling due to his genuine passion for
      problem-solving and technology. But he wanted to learn even more. That's
      why he decided to move to Australia and get a
      <b> Master's degree in IT at RMIT University</b>
      <div className="mt-8">
        {" "}
        He has developed skills in building full-stack web applications and has
        a strong interest in <b>Cloud Computing</b>. He has actively taught
        himself these skills outside of school projects and has earned an
        <b>
          <a
            href="https://www.credly.com/badges/66d69190-fde5-4a47-9933-6bd42ae6a78a"
            className="underline"
          >
            &#160;AWS Certified Cloud Practitioner certificate&#160;
          </a>
        </b>
        which he really pround of. Additionally, he is in the process of
        learning automation tools and building more cloud projects to further
        expand his expertise in the field.
      </div>{"  "}
      <div className="mt-8">
        When not online, he loves exploring vintage stores, volunteering for Op Shop and MLAI hackathorn events, and attending meet up groups to meet new people, get to know them, and learn about their cultures.
      </div>{" "}
    </section>
  );
};

export default About;

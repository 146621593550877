import React from 'react'

const Nav = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }
  return (
    <nav className="nav hidden lg:block">
    <ul class="mt-1 w-max">
      <li>
        <a class="group flex items-center py-3 active" href="#" onClick={scrollToTop()}>
          <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
          <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
            <span class="text-3xl">👨🏻‍💻 </span>About
          </span>
        </a>
      </li>
      <li>
        <a class="group flex items-center py-2" href="#education">
          <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
          <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
          <span class="text-3xl">🏫 </span> Education
          </span>
        </a>
      </li>
      <li>
        <a class="group flex items-center py-2" href="#experience">
          <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
          <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
          <span class="text-3xl">🌏 </span> Experience
          </span>
        </a>
      </li>
      <li>
      <a class="group flex items-center py-2" href="#projects">
          <span class="nav-indicator mr-4 h-px w-8 bg-slate-600 transition-all group-hover:w-16 group-hover:bg-slate-200 group-focus-visible:w-16 group-focus-visible:bg-slate-200 motion-reduce:transition-none"></span>
          <span class="nav-text text-xs font-bold uppercase tracking-widest text-slate-500 group-hover:text-slate-200 group-focus-visible:text-slate-200">
          <span class="text-3xl"> 🍀</span> Projects
          </span>
        </a>
      </li>
    </ul>
  </nav>
  )
}

export default Nav
import logo from "./logo.svg";
import "./App.css";
import About from "./About";
import Nav from "./Nav";
import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Experience from "./Experience";
import Projects from "./Projects";
import { IconsLang, Icons } from "./Icons";
import Education from "./Education";
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga4";
import axios from 'axios';

const TRACKING_ID = "G-7PCKTLSRWH";
function App() {
  //GA Test
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, []);
  const [count,setCount] = useState(null);
  useEffect(() => {
    axios.post(
      "https://oqcdfh8x8g.execute-api.ap-southeast-2.amazonaws.com/Production/updateCount"
    )
    .then(response => {
      setCount(response.data.body)
    })
    .catch(error => {
      console.error(error);
    });
  }, []);
  return (
    <>
      <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">
        <div className="lg:flex lg:justify-between lg:gap-4">
          <header className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:py-7">
            <img
              class="
                profile my-3 rounded hover:animate-rotate-y hover:animate-once"
              src="./profile.png"
              alt="Rounded avatar"
            />
            <h1 className="animate-fade-up text-4xl mt-4 font-bold sm:text-5xl">
              Chanakan Amornpatchara
            </h1>
            <h2 className="mt-5 text-lg font-medium  sm:text-xl">
              Master of IT Student &
            </h2>
            Senior Technical Support Consultant <IconsLang />
            <Nav />
            <Icons />
          </header>
          <div className="pt-24 lg:w-1/2 lg:py-24">
            <About />
            <Education />
            <Experience />
            <Projects />
          </div>
        </div>
        <ScrollToTop
          top={1800}
          smooth
          component={
            <div>
              <svg
                width="40px"
                height="40px"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14.5L12.5 10L17 14.5"
                  stroke="#121923"
                  stroke-width="1.2"
                />
              </svg>
            </div>
          }
        />
      </div>
      <div className="absolute right-24 py-4 px-10"> <div className="">© 2024 Chanakanamorn</div>Page Visits: {count} </div>
    </>
  );
}

export default App;
